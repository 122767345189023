import React from 'react';

export const IconFacebook = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <path id="facebooka" d="M0 0h20v20H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="facebookb" fill="#fff">
          <use xlinkHref="#facebooka" />
        </mask>
        <path d="M10 20c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10S0 4.478 0 10c0 5.523 4.477 10 10 10" fill="#E58061" mask="url(#facebookb)" />
      </g>
      <path d="M7.588 8.307H8.62V7.302c0-.443.011-1.127.334-1.55.338-.447.804-.753 1.604-.753 1.304 0 1.853.187 1.853.187l-.258 1.532s-.43-.125-.833-.125c-.401 0-.762.144-.762.546v1.168h1.649l-.115 1.496H10.56V15H8.621V9.803H7.588V8.307z" fill="#FFF" />
    </g>
  </svg>
);

export const IconTwitter = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <path id="twittera" d="M0 0h20v20H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="twitterb" fill="#fff">
          <use xlinkHref="#twittera" />
        </mask>
        <path d="M10 20c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10S0 4.478 0 10c0 5.523 4.477 10 10 10" fill="#E58061" mask="url(#twitterb)" />
      </g>
      <path d="M15.017 7.38a4.098 4.098 0 0 1-1.18.324c.424-.254.75-.657.903-1.137a4.087 4.087 0 0 1-1.305.498A2.055 2.055 0 0 0 9.934 8.94a5.833 5.833 0 0 1-4.237-2.147 2.054 2.054 0 0 0 .636 2.745 2.054 2.054 0 0 1-.931-.257v.026c0 .996.709 1.826 1.65 2.014a2.01 2.01 0 0 1-.93.036 2.054 2.054 0 0 0 1.92 1.427A4.124 4.124 0 0 1 5 13.635c.91.582 1.99.922 3.15.922 3.78 0 5.847-3.13 5.847-5.846 0-.09-.001-.18-.005-.266.401-.29.75-.652 1.025-1.064" fill="#FFF" />
    </g>
  </svg>
);

export const IconLinkedIn = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <path id="linkedina" d="M0 0h20v20H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="linkedinb" fill="#fff">
          <use xlinkHref="#linkedina" />
        </mask>
        <path d="M10 20c5.524 0 10-4.477 10-10S15.524 0 10 0C4.477 0 0 4.477 0 10s4.477 10 10 10" fill="#E58061" mask="url(#b)" />
      </g>
      <path d="M15 14.183h-2.21v-3.228c0-.845-.346-1.422-1.106-1.422-.582 0-.905.39-1.056.764-.056.135-.047.322-.047.51v3.376H8.39s.028-5.718 0-6.238h2.19v.979c.13-.428.829-1.04 1.946-1.04 1.385 0 2.473.898 2.473 2.83v3.469zM6.177 7.165h-.014C5.457 7.165 5 6.688 5 6.083 5 5.467 5.47 5 6.19 5c.72 0 1.162.466 1.176 1.082 0 .604-.456 1.083-1.189 1.083zm-.925 7.018h1.95V7.945h-1.95v6.238z" fill="#FFF" />
    </g>
  </svg>
);

export const MobileLogo = () => (
  <svg width="41" height="26" viewBox="0 0 41 26">
    <g fillRule="nonzero" fill="none">
      <path d="m20.675 22.53-.064-5.205a.807.807 0 0 0-.083-.403l-.109-.338.04-.17h.08l.13.056.006 6.06z" fill="#FFF" />
      <path d="m20.61 17.318.065 5.212v1.29a.283.283 0 0 1-.205.095.325.325 0 0 1-.262-.17v-.31c.018.02.034.04.05.06h.2l-.101-.181a1.226 1.226 0 0 1-.15-.47v-.767l.052-4.74c0-.193.037-.384.111-.562l.048-.207.108.339c.064.127.093.27.085.411z" fill="#FFF" />
      <path d="M21.234 13.84c.296-1.16 1.034-2.665 2.86-4.246a10.336 10.336 0 0 1-.66 2.658 7.04 7.04 0 0 1-2.387 3.088 6.355 6.355 0 0 1 .187-1.5zM16.191 9.313a.022.022 0 0 1 .01.009l.034.027c.04.564.122 1.125.245 1.677.036.17.073.329.117.496.087.348.195.69.322 1.025.156.41.353.804.589 1.174.633.98 1.104 1.48 2.146 2.096-2.056-.274-5.052-2.426-6.18-3.893a8.012 8.012 0 0 1-1.73-4.346c.787.131 1.561.327 2.316.586a6.566 6.566 0 0 1 2.131 1.15z" fill="#E5817B" />
      <path d="m40.805 23.343-3.198-1.32c-2.591-1.065-5.905-1.986-9.056-1.986-2.755 0-5.388.704-7.304 2.632-.198.2-.39.412-.572.637v-6.84l.459.2.28.04c4.371.585 7.716-.088 10.17-2.297l1.292-1.196c.478-.37 1.024.068 2.345 2.225l5.584 7.905z" fill="#38411A" />
      <path d="M22.552 22.7a5.11 5.11 0 0 0-1.42 1.205l-.227-.197c.2-.27.422-.525.662-.761 3.496-3.421 10.629-2.436 15.512-.476l2.991 1.266-1.537.486-3.377-1.32c-2.42-.847-6.115-2.214-11.145-.815-.51.142-1 .347-1.459.612zM20.201 22.7c-.002.045-.002.09 0 .135v.59c-2.094-2.457-5.046-3.297-8.125-3.297-3.113 0-6.358.857-8.885 1.894L0 23.342l5.417-8.072c1.32-2.156 2.238-2.25 2.439-2.057l1.34 1.185c2.032 1.67 4.698 2.56 9.141 2.321.386-.02.786-.05 1.2-.088l.678-.198v5.644l-.014.623z" fill="#38411A" />
      <path d="m37.985 24.617-1.354.425-2.954-1.158c-2.121-.75-5.361-1.941-9.769-.714a5.119 5.119 0 0 0-2.241 1.275 1.344 1.344 0 0 1-.195-.21c2.942-3.367 9.47-2.504 13.89-.728l2.623 1.11z" fill="#38411A" />
      <path d="m33.223 7.451.79.097c0 1.747-.13 5.713-5.29 8.125-1.81.846-5.382.96-7.28.76.806-.261 1.591-.581 2.35-.958 6.018-3.006 5.85-7.927 5.85-7.927l.955-.108c.872-.1 1.754-.096 2.625.011zM29.222 6.365c0 .233-.009.467-.03.697-.845.063-1.692.339-2.484.618a6.023 6.023 0 0 0-2.106 1.222c.08-1.495-.032-2.974-.508-4.337 1.309-1.185 2.854-1.83 4.478-2.23 0 0 .65 2.037.65 4.03z" fill="#E5817B" />
      <path d="M29.173 7.548a8.372 8.372 0 0 1-1.708 4.455c-.776 1.016-2.267 2.302-3.85 3.078a6.404 6.404 0 0 1-2.156.665c.636-.557 1.285-1.016 1.843-2.042.228-.416.43-.846.603-1.288.169-.428.304-.868.404-1.317.128-.54.215-1.089.261-1.642h.012a6.808 6.808 0 0 1 2.2-1.249 15.543 15.543 0 0 1 2.39-.66zM23.646 4.7c.447 1.422.6 2.92.448 4.402-1.354.846-2.175 2.214-2.644 2.965.114-1.071-.022-2.134-.548-3.471l-.086-.214.02-.039c.244-.458 1.254-2.35 2.81-3.642zM20.665 0c1.413 1.307 2.268 2.566 2.815 4.2-1.44 1.05-2.85 3.348-3.025 3.908a4.801 4.801 0 0 0-.097-.196c-.402-.769-1.645-2.805-2.75-3.64A10.346 10.346 0 0 1 20.665 0zM20.95 10.542a8.294 8.294 0 0 1-.199 3.217c-.193.724-.293 1.049-.293 1.049l-.426-1.06a6.052 6.052 0 0 1 .301-4.7l.037-.08c.041-.083.085-.17.126-.244.211.556.36 1.134.445 1.724 0 .03.005.064.008.094z" fill="#E5817B" />
      <path d="M20.675 16.28v.185l-.129-.056h-.265l-.08.027v-.08a.325.325 0 0 1 .262-.17.29.29 0 0 1 .212.095z" fill="#FFF" />
      <path fill="#FFF" d="m20.458 16.41-.039.164-.05-.165zM20.357 23.312l.101.181h-.2c-.016-.02-.032-.04-.05-.06v-.591c.017.165.068.325.149.47z" />
      <path d="m20.37 16.41.051.169-.05.196a1.456 1.456 0 0 0-.114.562l-.05 4.74v-5.64l.072-.023.091-.005z" fill="#FFF" />
      <path d="M20.201 22.075v.767a1.209 1.209 0 0 1 0-.136v-.631z" fill="#232E17" />
      <path d="M19.898 8.03c.1.17.17.295.208.37a6.73 6.73 0 0 0-.694 1.885c-.127.601-.24 1.099-.152 1.708-.557-.997-1.57-2.182-2.473-2.817-.088-1.17-.093-2.063.064-2.965a8.982 8.982 0 0 1 .43-1.581c.735.494 2.074 2.496 2.617 3.4z" fill="#E5817B" />
      <path d="m19.893 23.703-.227.197a5.11 5.11 0 0 0-1.42-1.206 6.31 6.31 0 0 0-1.46-.612c-5.03-1.4-8.725-.04-11.146.814l-3.382 1.327-1.523-.49 2.992-1.267c5.207-2.094 12.99-3.076 16.166 1.237z" fill="#38411A" />
      <path d="M19.582 13.857c.144.482.23.98.257 1.483a6.128 6.128 0 0 1-1.893-1.83 6.334 6.334 0 0 1-.56-1.084c-.13-.326-.24-.66-.328-1a10.47 10.47 0 0 1-.271-1.542c1.442 1.388 2.37 2.575 2.795 3.973z" fill="#E5817B" />
      <path d="M19.32 24.154a1.354 1.354 0 0 1-.194.211 5.124 5.124 0 0 0-2.253-1.252c-4.421-1.185-7.648.042-9.762.812L4.164 25.11l-1.354-.411 2.612-1.136c4.404-1.818 10.923-2.746 13.899.59z" fill="#38411A" />
      <path d="M19.084 16.333c-.234.026-.49.044-.765.056-1.955.091-4.825-.129-6.485-.846-4.762-2.067-5.292-6.67-5.292-8.125l1.102-.09c.847-.07 2.717-.088 3.62.14.038.541.126 1.077.264 1.602.016.062.033.127.052.19.572 2.01 2.031 5.044 7.504 7.073zM16.816 4.496a9.906 9.906 0 0 0-.42 1.638 11.763 11.763 0 0 0-.139 2.641c-.579-.547-1.253-.816-1.992-1.073a9.887 9.887 0 0 0-2.479-.576 8.18 8.18 0 0 1-.028-.71c0-2.013.64-4.155.64-4.155 1.623.4 3.114 1.048 4.418 2.235z" fill="#E5817B" />
      <path d="M16.201 9.327a.032.032 0 0 1 .012.01l-.012-.01z" fill="#E68062" />
    </g>
  </svg>
);

export const IconHamburger = () => (
  <svg width="16" height="11" viewBox="0 0 16 11">
    <g stroke="#E58061" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M.5.5h15M.5 5.5h15M.5 10.5h15" />
    </g>
  </svg>
);

export const IconCloseMenu = () => (
  <svg width="14" height="13" viewBox="0 0 14 13">
    <g stroke="#E58061" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M1.696 11.803 12.303 1.197M1.697 1.196l10.606 10.607" />
    </g>
  </svg>
);
