/* eslint camelcase: "off"*/
import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import SignIn from './sign-in';
import { SpinnerWhite } from '../Icons';
import Layout from '../Layout';
import { TextButton } from '../Button';
import ResendVerificationLink from './resend-verification-link';

export default function VerifyEmail ({
  authState,
  handleAuthStateChange,
  user
}) {
  const params = useParams();
  const [ code, setCode ] = useState(params?.code);
  const [ stateMessage, setStateMessage ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ globalError, setGlobalError ] = useState('');
  const [ codeError, setCodeError ] = useState('');
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const plan = searchParams.get('plan');
  const term = searchParams.get('term');
  const appHistory = useHistory();

  async function checkState () {
    if (authState !== AuthState.SignedIn) return;

    if (user?.attributes?.email_verified) {
      return setStateMessage('Your email address is already verified.');
    }

    const userAttributes = await Auth.userAttributes(user);

    const attr = userAttributes.find(ua => ua.Name === 'email_verified');

    if (attr?.Value === 'true') {
      setStateMessage('Your email address is already verified.');
    }
  }

  useEffect(() => { checkState(); }, [ authState ]);

  const loadingSpinner = useMemo(() => {
    if (!loading) return null;

    return ( <SpinnerWhite style={{ width: '20px', height: '20px' }} /> );
  }, [ loading ]);

  const globalErrorRendered = useMemo(() => {
    if (!globalError) return null;

    return (
      <div className="errors">
        <p className="body error">{globalError}</p>
      </div>
    );
  }, [ globalError ]);

  const codeErrorRendered = useMemo(() => {
    if (!codeError) return null;

    return (
      <div className="errors">
        <p className="body error">{codeError}</p>
      </div>
    );
  }, [ codeError ]);

  async function redirectToCheckout () {
    if (plan && term) {
      appHistory.push(`/payment-type?plan=${plan}&term=${term}`);
    }
  }

  function handleSubmit (ev) {
    ev.preventDefault();

    setGlobalError('');
    setCodeError('');
    setLoading(true);

    Auth.verifyCurrentUserAttributeSubmit('email', code).then((state) => {
      if (state === 'SUCCESS') {
        window.dataLayer.push({ event: 'sign_up_verify' });
        if (plan) {
          setStateMessage('Your email address is confirmed. Please wait.');
          redirectToCheckout();
        } else {
          setStateMessage('Your email address is confirmed.');
        }
      } else {
        setGlobalError('Something went wrong. Please try again later');
      }
      setLoading(false);
    }, (err) => {
      console.error(err);

      if (err.code && err.message) {
        setCodeError(err.message);
      } else {
        setGlobalError('Something went wrong. Please try again later');
      }

      setLoading(false);
    });
  }

  if (authState !== AuthState.SignedIn) {
    return (
      <Layout backgroundStyle="collection">
        <div className="start-screen">
          <h1 className="h1 auth-header">Login to <strong>Your Library</strong></h1>
          <div className="auth-container">
            <div className="auth-container__inner">
              <SignIn authStateOptions={{}} handleAuthStateChange={handleAuthStateChange} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (stateMessage) {
    return (
      <Layout backgroundStyle="collection">
        <div className="sign-up">
          <div className="page-title-container text-center">
            <h1 className="h1">Verify email</h1>
          </div>

          <p className="state-message">{stateMessage}</p>

          {
            plan ? null : (
              <div className="submit-container" style={{ textAlign: 'center' }}>
                <TextButton filled to="/my-collection">
                  Go to My Collection
                </TextButton>
              </div>
            )
          }
        </div>
      </Layout>
    );
  }

  return (
    <Layout backgroundStyle="collection">
      <div className="sign-up">
        <div className="page-title-container text-center">
          <h1 className="h1">Verify email</h1>
        </div>

        <form className="sign-up-form" onSubmit={handleSubmit}>
          <div className="field text-field">
            <p className="instructions">
              An email has been sent to you with a verification code. Enter the verification code to
              continue.
            </p>
            <label htmlFor="code">Verification Code</label>
            <input
              type="text"
              className="input"
              name="code"
              placeholder="12345678"
              value={code}
              onChange={ev => setCode(ev.target.value)}
              required
            />
            {codeErrorRendered}
          </div>

          {globalErrorRendered}

          <div className="submit-container">
            <ResendVerificationLink />

            <TextButton filled formType="submit" disabled={loading}>
              { loadingSpinner ?? 'Verify' }
            </TextButton>
          </div>
        </form>
      </div>
    </Layout>
  );
}
