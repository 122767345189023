import React from 'react';
import ButtonWrapper from './button-wrapper';

const TextButton = ({
  children,
  to,
  onClick,
  handleClick,
  className,
  navlink,
  variant,
  filled,
  formType,
  disabled,
  active,
  outlined,
  target
}) => {
  let type = 'button';

  if (to && !navlink) {
    type = 'link';
  } else if (to && navlink) {
    type = 'navlink';
  }

  const buttonClasses = [
    'text-button',
    'button',
    className || '',
    variant || '',
    filled ? 'filled' : '',
    active ? 'active' : '',
    outlined ? 'outlined' : ''
  ].join(' ');

  return (
    <ButtonWrapper
      handleClick={onClick || handleClick}
      className={buttonClasses}
      type={type}
      to={to}
      formType={formType}
      disabled={disabled}
      target={target}
    >
      {children}
    </ButtonWrapper>
  );
};

export default TextButton;
