/* global process */
import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { Logo } from '../Icons';
import { useProfileImage } from '../../common/ProfileImage/use-profile-image';
import { useHistory, Link, NavLink } from 'react-router-dom';
import { getUserProfile, getSubscriptionPortal, getPrismicNav } from '../../util/api';
import { Auth } from 'aws-amplify';
import { forgetSubscription } from '../ProtectedRoute/check-subscription';
import { useAsyncError } from '../../common/Errors/use-async-error';
import { IconFacebook, IconTwitter, IconLinkedIn, MobileLogo, IconHamburger, IconCloseMenu } from './site-icons';
import NavigationPromptContextProvider, { NavigationPromptContext }
  from './navigation-prompt-context';
import './styles.scss';

const LANDING_PAGE_DOMAIN = process.env.LANDING_PAGE_DOMAIN;

function fixEnvLink (url) {
  if (!process.env.ENV_TAG) return url;
  return url.replace('.yogatherapylibrary.com',
    `.${process.env.ENV_TAG}.yogatherapylibrary.com`);
}

export { NavigationPromptContextProvider };

export async function gotoYourAccount (ev, returnUrl) {
  ev?.preventDefault();

  const userProfile = await getUserProfile();
  const isPaypal = !!userProfile.paypalSubscriptionId;

  if (isPaypal) {
    window.location.href = '/billing';
    return;
  }

  try {
    sessionStorage.setItem('stripePortalRedirect', 't');
    forgetSubscription();
    const portalSession = await getSubscriptionPortal({
      returnUrl: returnUrl ?? window.location.href
    });
    window.location = portalSession.url;
  } catch (e) {
    console.error(`Error getting subscription portal URL`, e);
    window.location.href = '/';
  }
}

const Header = ({
  authState,
  handleAuthStateChange
}) => {
  const [ mobileNavOpen, setMobileNavOpen ] = useState(false);
  const [ prismicFooterData, setPrismicFooterData ] = useState(null);
  const [ prismicNav, setPrismicNav ] = useState(null);
  const throwAsyncError = useAsyncError();
  const browserHistory = useHistory();
  const { setProfileImage } = useProfileImage();
  const navigationPrompt = useContext(NavigationPromptContext);

  useEffect(() => {
    if (!prismicNav) {
      getPrismicNav()
        .then((res) => res.json())
        .then((res) => {
          const newPrismicNav = res?.results[0]?.data?.library_nav ?? [];
          newPrismicNav.push(...(res?.results[0]?.data?.extra_footer_nav ?? []));
          setPrismicNav(newPrismicNav);

          if (res?.results[0]?.data) {
            setPrismicFooterData(res.results[0].data);
          }
        }, throwAsyncError);
    }
  }, [ prismicNav ]);

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      getUserProfile().then((res) => {
        if (res.image) {
          setProfileImage(res.image);
        }
      });
    }
  }, [ authState ]);

  const handleMobileNavToggle = useCallback(() => {
    setMobileNavOpen(!mobileNavOpen);
  }, [ mobileNavOpen ]);

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      console.error('error when logging out: ', e);
      throw e;
    }

    sessionStorage.clear();

    handleAuthStateChange(AuthState.SignedOut);
    browserHistory.push('/');
  };

  const logOut = () => {
    signOut().catch(throwAsyncError);
  };

  const prismicNavItemsRendered = useMemo(() => {
    if (!prismicNav || !prismicNav.length) return null;

    return prismicNav.map((item, i) => {
      const {
        text,
        link,
        link_logged_in
      } = item;

      let linkURL = '';

      if (authState === AuthState.SignedIn && ( link_logged_in?.url || link_logged_in?.uid )) {
        const { link_type } = link_logged_in;

        if (link_type === 'Document') {
          linkURL = `${LANDING_PAGE_DOMAIN}/${link_logged_in.uid}`;

          if (link_logged_in.uid === 'home') {
            linkURL = LANDING_PAGE_DOMAIN;
          }
        } else if (link_type === 'Web') {
          linkURL = fixEnvLink(link_logged_in.url);
        }
      } else {
        const { link_type } = link;

        if (link_type === 'Document') {
          linkURL = `${LANDING_PAGE_DOMAIN}/${link.uid}`;

          if (link.uid === 'home') {
            linkURL = LANDING_PAGE_DOMAIN;
          }
        } else if (link_type === 'Web') {
          linkURL = fixEnvLink(link.url);
        }
      }

      const linkText = 'string' == typeof text ? text : text[0].text;

      return (
        <li key={i} className="nav-list-item">
          <a href={linkURL} onClick={navigationPrompt.onClick}>{linkText}</a>
        </li>
      );
    });
  }, [ prismicNav, authState, navigationPrompt ]);

  const mobileNavFooterRendered = useMemo(() => {
    if (!prismicFooterData) return null;

    const {
      copyright,
      facebook_url,
      twitter_url,
      terms_of_service_link,
      privacy_policy_link,
      linkedin_url
    } = prismicFooterData;

    return (
      <div className="mobile-nav__footer">
        <div className="social-buttons">
          {
            facebook_url?.url ? (
              <a
                className="social-btn"
                target="_blank"
                rel="noreferrer"
                href={facebook_url.url}
                onClick={navigationPrompt.onClick}
              >
                <IconFacebook />
              </a>
            ) : null
          }
          {
            twitter_url?.url ? (
              <a
                className="social-btn"
                target="_blank"
                rel="noreferrer"
                href={twitter_url.url}
                onClick={navigationPrompt.onClick}
              >
                <IconTwitter />
              </a>
            ) : null
          }
          {
            linkedin_url?.url ? (
              <a
                className="social-btn"
                target="_blank"
                rel="noreferrer"
                href={linkedin_url.url}
                onClick={navigationPrompt.onClick}
              >
                <IconLinkedIn />
              </a>
            ) : null
          }
        </div>
        <p className="copyright">{copyright}</p>
        <div className="legal-btns">
          {
            terms_of_service_link?.uid ? (
              <a
                className="legal-btn"
                target="_blank"
                rel="noreferrer"
                href={`${LANDING_PAGE_DOMAIN}/${terms_of_service_link.uid}`}
                onClick={navigationPrompt.onClick}
              >
                Terms &amp; Conditions
              </a>
            ) : null
          }
          {
            privacy_policy_link?.uid ? (
              <a
                className="legal-btn"
                target="_blank"
                rel="noreferrer"
                href={`${LANDING_PAGE_DOMAIN}/${privacy_policy_link.uid}`}
                onClick={navigationPrompt.onClick}
              >
                Privacy Policy
              </a>
            ) : null
          }
        </div>
      </div>
    );
  }, [ prismicFooterData, navigationPrompt ]);

  const siteMainNavRendered = useMemo(() => {
    if (!prismicFooterData) return null;

    const { main_nav } = prismicFooterData;

    return main_nav.map((navItem, i) => {
      const {
        text,
        link
      } = navItem;

      let linkURL = '';

      const { link_type } = link;

      if (link_type === 'Document') {
        linkURL = `${LANDING_PAGE_DOMAIN}/${link.uid}`;

        if (link.uid === 'home') {
          linkURL = LANDING_PAGE_DOMAIN;
        }
      } else if (link_type === 'Web') {
        linkURL = fixEnvLink(link.url);
      }

      return (
        <li
          key={`mobile-nav-list-item-${i}`}
          className="mobile-nav-list-item"
        >
          <a href={linkURL} onClick={navigationPrompt.onClick}>
            { text[0].text }
          </a>
        </li>
      );
    });
  }, [ prismicFooterData, navigationPrompt ]);

  function handleDeactivateMobileNav () {
    setMobileNavOpen(false);
  }

  const headerContents = () => {
    switch (authState) {
      case AuthState.SignedIn:
        return (
          <div className="app-header--signed-in">
            <div className="mobile-col mobile-col-left">
              <div className="mob-logo">
                <a href={LANDING_PAGE_DOMAIN} onClick={navigationPrompt.onClick}>
                  <MobileLogo />
                </a>
              </div>
              <div className="tablet-logo">
                <a href={LANDING_PAGE_DOMAIN} onClick={navigationPrompt.onClick}>
                  <Logo />
                </a>
              </div>
              <span className="site-title">Sequence Builder</span>
            </div>
            <div className="mobile-col mobile-col-right">
              <div
                className="mobile-nav-toggle"
                onClick={handleMobileNavToggle}
                tabIndex="0"
                role="button"
                onKeyDown={handleMobileNavToggle}
              >
                { mobileNavOpen ? <IconCloseMenu /> : <IconHamburger />}
              </div>
            </div>
            <div className="col-left header-col">
              <div className="logo-container">
                {/*This is intentionally a basic anchor tag so it will refresh/reset the app*/}
                <a href={LANDING_PAGE_DOMAIN} onClick={navigationPrompt.onClick}><Logo /></a>
              </div>
              <nav className="library-nav">
                <span className="menu-title">Sequence Builder</span>
                <div className="nav-dropdown">
                  <ul className="nav-list prismic-nav">
                    { prismicNavItemsRendered }
                  </ul>
                  <ul className="nav-list app-nav">
                    <li className="nav-list-item">
                      <a
                        href={`${LANDING_PAGE_DOMAIN}/about-us`}
                        onClick={navigationPrompt.onClick}
                      >
                        About Yoga Therapy Library
                      </a>
                    </li>
                    <li className="nav-list-item">
                      <Link to={'/user'}>Settings</Link>
                    </li>
                    <li className="nav-list-item">
                      <div
                        className="your-account"
                        onClick={navigationPrompt.promptToConfirm(gotoYourAccount)}
                      >
                        Billing
                      </div>
                    </li>
                    <li className="nav-list-item --run-tutorial">
                      <div
                        className="run-tutorial"
                        onClick={ev => ev.preventDefault()}
                      >
                        Run Tutorial
                      </div>
                    </li>
                    <li className="nav-list-item">
                      <div
                        className="log-out"
                        onClick={navigationPrompt.promptToConfirm(logOut)}
                      >
                        Log Out
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-right header-col">
              <ul className="nav-list top-level">
                <li className="nav-list-item">
                  <NavLink to={'/my-collection'}>My Collection</NavLink>
                </li>
                <li className="nav-list-item">
                  <NavLink to={'/public-sequences'}>Public Sequences</NavLink>
                </li>
              </ul>
            </div>
            <nav className={`mobile-nav ${mobileNavOpen ? 'active' : ''}`}>
              <div className="green-bg" />
              <div className="mobile-nav__inner">
                <ul className="mobile-nav-list" onClick={handleDeactivateMobileNav}>
                  <li className="nav-list-item">
                    <NavLink to={'/my-collection'}>My Collection</NavLink>
                  </li>
                  <li className="nav-list-item">
                    <NavLink to={'/public-sequences'}>Public Sequences</NavLink>
                  </li>
                  {prismicNavItemsRendered}
                  <li className="nav-list-item">
                    <a
                      href={`${LANDING_PAGE_DOMAIN}/about-us`}
                      onClick={navigationPrompt.onClick}
                    >About Yoga Therapy Library</a>
                  </li>
                  <li className="nav-list-item">
                    <Link to={'/user'}>Settings</Link>
                  </li>
                  <li className="nav-list-item">
                    <div
                      className="your-account"
                      onClick={navigationPrompt.promptToConfirm(gotoYourAccount)}
                    >
                      Billing
                    </div>
                  </li>
                  <li className="nav-list-item">
                    <div
                      className="log-out"
                      onClick={navigationPrompt.promptToConfirm(logOut)}
                    >
                      Log Out
                    </div>
                  </li>
                </ul>
                { mobileNavFooterRendered }
              </div>
            </nav>
          </div>
        );
      default:
        return (
          <div className="app-header--signed-out">
            <div className="logo-container">
              {/*This is intentionally a basic anchor tag so it will refresh/reset the app*/}
              <a href={LANDING_PAGE_DOMAIN} onClick={navigationPrompt.onClick}><Logo /></a>
            </div>
            <div className="nav-container">
              <div
                className="mobile-nav-toggle"
                onClick={handleMobileNavToggle}
                tabIndex="0"
                role="button"
                onKeyDown={handleMobileNavToggle}
              >
                { mobileNavOpen ? <IconCloseMenu /> : <IconHamburger />}
              </div>
              <nav className={`site-nav ${mobileNavOpen ? 'active' : ''}`}>
                <div className="green-bg" />
                <div className="site-nav__inner">
                  <div className="nav-list-container">
                    <ul className="nav-list">
                      <li className="nav-list-item --with-submenu">
                        <a href={LANDING_PAGE_DOMAIN} onClick={navigationPrompt.onClick}>Library</a>
                        <ul className="sub-nav">
                          { prismicNavItemsRendered }
                        </ul>
                      </li>
                      { siteMainNavRendered }
                      <li className="mobile-nav-list-item">
                        <a href="/" onClick={navigationPrompt.onClick}>Log in</a>
                      </li>

                    </ul>
                    <a
                      href={`${LANDING_PAGE_DOMAIN}/subscriptions`}
                      className="text-button"
                      onClick={navigationPrompt.onClick}
                    >
                      Subscribe
                    </a>
                  </div>
                  { mobileNavFooterRendered }
                </div>
              </nav>
            </div>
          </div>
        );
    }
  };

  const hide = window.location.pathname.indexOf('pdf') > -1;

  return (
    <header className="app-header" hidden={hide}>
      {headerContents()}
    </header>
  );
};

export default Header;
