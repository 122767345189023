import React from 'react';
import { useAPIError } from '../../common/Errors/use-api-error';
import './styles.scss';

const ErrorNotification = () => {
  const { error, removeError } = useAPIError();

  const handleClose = () => {
    removeError();
  };

  if (!error) return null;

  const msg = error.message || 'Something went wrong';

  return (
    <div className="error-notification">
      <div className="error-notification__inner">
        { msg }
        <div
          className="close-error"
          onClick={handleClose}
        >
          &times;
        </div>
      </div>
    </div>
  );
};

export default ErrorNotification;
