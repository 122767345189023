/* eslint camelcase: "off"*/
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import { useAsyncError } from '../../common/Errors/use-async-error';
import { PersonIcon, SpinnerWhite } from '../Icons';
import Layout from '../Layout';
import { TextButton } from '../Button';
import validator from 'validator';
import { updateUserProfile, getSubscriptionPortal, getUserProfile } from '../../util/api';
import { useSubscription } from '../ProtectedRoute/check-subscription';

const SignUp = ({
  authState,
  authStateOptions,
  handleAuthStateChange
}) => {
  const params = useParams();
  const throwAsyncError = useAsyncError();
  const subscription = useSubscription();
  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ emailConfirm, setEmailConfirm ] = useState('');
  const [ userName, setUserName ] = useState('');
  const [ userHandle, setUserHandle ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setPasswordConfirm ] = useState('');
  const [ userImage, setUserImage ] = useState({});
  const [ globalError, setGlobalError ] = useState('');
  const [ emailError, setEmailError ] = useState('');
  const [ emailConfirmError, setEmailConfirmError ] = useState('');
  const [ userHandleError, setUserHandleError ] = useState('');
  const [ passwordError, setPasswordError ] = useState('');
  const [ passwordConfirmError, setPasswordConfirmError ] = useState('');

  const appHistory = useHistory();

  useEffect(() => {
    if (!authState || !subscription) return;

    if (authState === AuthState.SignedIn && authStateOptions?.from === AuthState.SignUp) return;

    let stripePortalRedirect = sessionStorage.getItem('stripePortalRedirect');

    if (stripePortalRedirect) {
      stripePortalRedirect = parseInt(stripePortalRedirect);

      if (appHistory.length - stripePortalRedirect > 2) {
        stripePortalRedirect = null;
        sessionStorage.removeItem('stripePortalRedirect');
      }
    }

    if (appHistory.action === 'POP' && authState === AuthState.SignedIn && stripePortalRedirect) {
      setLoading(true);
      appHistory.push('/');
      return;
    }

    if (authState === AuthState.SignedIn && params?.plan && params?.term) {
      setLoading(true);
      sessionStorage.setItem('stripePortalRedirect', appHistory.length);

      if (subscription.active) {
        getUserProfile().then(profileRes => {
          if (profileRes.paypalSubscriptionId) {
            appHistory.push('/billing');
          } else {
            getSubscriptionPortal({ returnUrl: `${window.location.origin}/` }).then((res) => {
              window.location = res.url;
            }, throwAsyncError);
          }
        });
      } else {
        appHistory.push(`/payment-type?plan=${params.plan}&term=${params.term}`);
      }
    } else if (authState !== AuthState.SignIn) {
      sessionStorage.removeItem('stripePortalRedirect');
      setLoading(true);
      appHistory.push('/');
    }
  }, [ authState, authStateOptions, subscription ]);

  if (!authState || ( authState === AuthState.SignedIn && params?.plan && params?.term )) {
    return (
      <Layout backgroundStyle="collection">
        <div className="sign-up">
          <div className="page-title-container text-center">
            <h1 className="h1">Please wait...</h1>
          </div>
        </div>
      </Layout>
    );
  }

  const handleImageChange = (ev) => {
    if (ev.target.files.length) {
      setUserImage({
        preview: URL.createObjectURL(ev.target.files[0]),
        raw: ev.target.files[0]
      });
    }
  };

  async function doSignup () {
    try {
      await Auth.signUp({
        username: email?.toLowerCase(),
        attributes: { email: email?.toLowerCase() },
        password
      });
    } catch (e) {
      console.error(e);
      setLoading(false);

      if (e.message && /Exception$/.test(e?.code)) {
        return setGlobalError(e.message);
      }

      return setGlobalError('Signup failed');
    }

    try {
      const user = await Auth.signIn(email?.toLowerCase(), password);
      handleAuthStateChange(AuthState.SignedIn, user, { from: AuthState.SignUp });
      window.dataLayer.push({ event: 'sign_up', user_id: user.username });
    } catch (e) {
      console.error(e);
      setLoading(false);
      //TODO cleanup created account
      return setGlobalError('Signin failed');
    }

    const [ verifyRes, updateRes ] = await Promise.allSettled([
      Auth.verifyCurrentUserAttribute('email'),
      updateUserProfile({ userName, handle: userHandle })
    ]);

    let failed = false;
    if (verifyRes.status === 'rejected') {
      failed = true;
      console.error(verifyRes.reason);
    }

    if (updateRes.status === 'rejected') {
      failed = true;
      console.error(updateRes.reason);
    }

    setLoading(false);

    if (failed) {
      //TODO sign out and cleanup created account
      return setGlobalError("Post-signup steps failed");
    }

    if (params?.plan && params?.term) {
      return appHistory.push(`/verify-email?plan=${params.plan}&term=${params.term}`);
    }

    appHistory.push('/');
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();

    let errors = 0;

    if (!email || !emailConfirm || !userName || !userHandle || !password || !passwordConfirm) {
      errors += 1;
      setGlobalError('All fields are required');
    } else {
      setGlobalError(null);
    }

    if (!validator.isEmail(email)) {
      errors += 1;
      setEmailError('Must be a valid email address.');
    } else {
      setEmailError(null);
    }

    if (email !== emailConfirm) {
      errors += 1;
      setEmailConfirmError('Email addresses must match.');
    } else {
      setEmailConfirmError(null);
    }

    if (userHandle.length < 3) {
      errors += 1;
      setUserHandleError('Username must be more than three characters.');
    } else {
      setUserHandleError(null);
    }

    if (password.length < 8 || !validator.isAscii(password)) {
      errors += 1;
      setPasswordError('Password invalid.');
    } else {
      setPasswordError(null);
    }

    if (password !== passwordConfirm) {
      errors += 1;
      setPasswordConfirmError('Passwords must match.');
    } else {
      setPasswordConfirmError(null);
    }

    if (errors) return;

    setLoading(true);
    doSignup();
  };

  return (
    <Layout backgroundStyle="collection">
      <div className="sign-up">
        <div className="page-title-container text-center">
          <h1 className="h1">Create Account</h1>
        </div>
        <div className="user-image">
          <div className="image-thumbnail">
            {
              userImage.preview ? (
                <img
                  src={userImage.preview}
                  alt="User Profile Image"
                />
              ) : <PersonIcon />
            }
          </div>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <label
            className="text-link"
            htmlFor="upload-button"
          >
            Update Image
          </label>
        </div>
        <form className="sign-up-form" onSubmit={handleSubmit}>
          <div className="field text-field">
            <label htmlFor="userName">Your Name</label>
            <input
              type="text"
              className="input"
              value={userName}
              name="userName"
              onChange={(ev) => setUserName(ev.target.value)}
              placeholder="Jane Namaste"
            />
          </div>
          <div className="field text-field">
            <label htmlFor="userHandle">Public Name</label>
            <input
              type="text"
              className="input"
              value={userHandle}
              name="userHandle"
              onChange={(ev) => setUserHandle(ev.target.value)}
              placeholder="janenamaste"
            />
            {
              userHandleError ? (
                <div className="errors">
                  <p className="body error">{userHandleError}</p>
                </div>
              ) : ''
            }
          </div>
          <div className="field text-field">
            <label htmlFor="userEmail">Email Address</label>
            <input
              type="text"
              className="input"
              value={email}
              name="userEmail"
              onChange={(ev) => setEmail(ev.target.value)}
              placeholder="janenamaste@address.com"
            />
            {
              emailError ? (
                <div className="errors">
                  <p className="body error">{emailError}</p>
                </div>
              ) : ''
            }
          </div>
          <div className="field text-field">
            <label htmlFor="userEmailConfirm">Confirm Email Address</label>
            <input
              type="text"
              className="input"
              value={emailConfirm}
              name="userEmailConfirm"
              onChange={(ev) => setEmailConfirm(ev.target.value)}
              placeholder="janenamaste@address.com"
            />
            {
              emailConfirmError ? (
                <div className="errors">
                  <p className="body error">{emailConfirmError}</p>
                </div>
              ) : ''
            }
          </div>
          <div className="field text-field">
            <label htmlFor="password">Create Password</label>
            <input
              type="password"
              className="input"
              value={password}
              name="password"
              onChange={(ev) => setPassword(ev.target.value)}
            />
            {
              passwordError ? (
                <div className="errors">
                  <p className="body error">{passwordError}</p>
                </div>
              ) : ''
            }
          </div>
          <div className="field text-field">
            <label htmlFor="passwordConfirm">Confirm Password</label>
            <input
              type="password"
              className="input"
              value={passwordConfirm}
              name="passwordConfirm"
              onChange={(ev) => setPasswordConfirm(ev.target.value)}
            />
            {
              passwordConfirmError ? (
                <div className="errors">
                  <p className="body error">{passwordConfirmError}</p>
                </div>
              ) : ''
            }
          </div>
          {
            globalError ? (
              <div className="errors">
                <p className="body error">{globalError}</p>
              </div>
            ) : ''
          }
        </form>
        <div className="submit-container">
          <TextButton
            filled
            handleClick={handleSubmit}
          >
            { loading ? <SpinnerWhite
              style={{
                width: '20px',
                height: '20px'
              }}
            /> : 'Sign Up' }
          </TextButton>
        </div>
      </div>
    </Layout>
  );
};

export default SignUp;
