/*globals process*/
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { SpinnerWhite } from '../Icons';
import { AuthState, ChallengeName } from '@aws-amplify/ui-components';
import { TextButton } from '../Button';

const SignIn = ({
  handleAuthStateChange,
  authStateOptions
}) => {
  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ loginFailed, setLoginFailed ] = useState('');

  const handleEmailChange = (ev) => {
    setEmail(ev.target.value);
  };

  const handlePasswordChange = (ev) => {
    setPassword(ev.target.value);
  };

  const doSignIn = async () => {
    var user, nextAuthState;

    try {
      user = await Auth.signIn(email?.toLowerCase(), password);

      console.debug(user);

      if (user.challengeName === ChallengeName.NewPasswordRequired) {
        nextAuthState = [ ChallengeName.NewPasswordRequired, user ];
      } else if (user.challengeName) {
        throw new Error(`Challenge not supported! ${user.challengeName}`);
      } else {
        nextAuthState = [ AuthState.SignedIn, user ];
      }
    } catch (e) {
      if (e.code === 'PasswordResetRequiredException') {
        console.warn('the user requires a new password');
        nextAuthState = [ AuthState.ForgotPassword, { email: email?.toLowerCase() } ];
      } else if (e.code === 'NotAuthorizedException') {
        console.info(e);
        setLoading(false);
        setLoginFailed(e.message);
      } else {
        console.error(`Error signing in: ${e.stack ? e.stack : JSON.stringify(e)}`);
        throw e;
      }
    }

    if (nextAuthState) {
      handleAuthStateChange(...nextAuthState);
      setTimeout(() => {
        window.location.href = process.env.LANDING_PAGE_DOMAIN;
      }, 50);
    }
  };

  const handleSignIn = (ev) => {
    ev.preventDefault();

    setLoading(true);

    doSignIn().then(() => {
      console.info('Signed in');
    }, err => {
      console.error(`Error signing in: ${err.stack ? err.stack : JSON.stringify(err)}`);
      setLoading(false);
    });
  };

  const handleForgotPassword = (ev) => {
    ev.preventDefault();

    setLoading(true);

    handleAuthStateChange(AuthState.ForgotPassword, { email: email?.toLowerCase() });
  };

  const renderLoginFailed = () => {
    if (!loginFailed) return;

    return (
      <div className="auth-error errors text-center">
        <span className="error">Login failed!</span>
      </div>
    );
  };

  const renderPrompt = () => {
    if (!authStateOptions) return;

    if (authStateOptions.from === AuthState.ForgotPassword) {
      return (
        <h4 className="h4 directions">Login with your new password.</h4>
      );
    }

    return;
  };

  return (
    <form className="sign-in" onSubmit={handleSignIn}>
      {renderPrompt()}
      <div className="field text-field">
        <label htmlFor="email">Email Address</label>
        <input
          className="input"
          id="email"
          type="email"
          autoComplete="username"
          placeholder="email@address.com"
          required
          onChange={handleEmailChange}
        />
      </div>

      <div className="field text-field">
        <label htmlFor="email">Password</label>
        <input
          className="input"
          id="password"
          type="password"
          autoComplete="current-password"
          placeholder="Password"
          required
          onChange={handlePasswordChange}
        />
      </div>
      <div className="form-link">
        <a className="text-link" href="#" onClick={handleForgotPassword}>Forgot Password?</a>
      </div>
      { renderLoginFailed() }
      <div className="submit-button">
        <TextButton
          formType="submit"
          disabled={loading}
          filled
        >
          { loading ? <SpinnerWhite
            style={{
              width: '20px',
              height: '20px'
            }}
          /> : <span>Submit</span> }
        </TextButton>
        {/* <button className="primary-btn" type="submit" disabled={loading}>

          { loading ? <Spinner /> : '' }
          <span style={{ display: loading ? 'none' : 'initial' }}>
            Submit
          </span>

        </button> */}
      </div>
    </form>
  );
};

export default SignIn;
