import React from 'react';
import ButtonWrapper from './button-wrapper';

const FAB = ({
  children,
  to,
  handleClick,
  onBlur,
  className,
  navlink,
  large,
  small,
  variant,
  tooltip,
  target,
  disabled,
  dataItemId
}) => {
  let type = 'button';

  if (to && !navlink) {
    type = 'link';
  } else if (to && navlink) {
    type = 'navlink';
  }

  return (
    <ButtonWrapper
      handleClick={handleClick}
      className={`
        fab-button
        button
        ${className}
        ${large ? `fab--large` : ``}
        ${small ? `fab--small` : ``}
        ${variant ? `variant-${variant}` : ``}
      `}
      type={type}
      to={to}
      target={target}
      disabled={disabled}
      onBlur={onBlur}
      data-item-id={dataItemId}
    >
      {children}
      {
        tooltip ? (
          <div className="button-tooltip">
            {tooltip}
          </div>
        ) : null
      }
    </ButtonWrapper>
  );
};

export default FAB;
