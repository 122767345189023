/* eslint-disable react/jsx-handler-names */
import React from 'react';
import ButtonWrapper from './button-wrapper';

const IconButton = ({
  children,
  to,
  onClick: handleClick,
  className,
  target,
  navlink
}) => {
  let type = 'button';

  if (to && !navlink) {
    type = 'link';
  } else if (to && navlink) {
    type = 'navlink';
  }

  return (
    <ButtonWrapper
      handleClick={handleClick}
      className={`icon-button button ${className}`}
      type={type}
      to={to}
      target={target}
    >
      {children}
    </ButtonWrapper>
  );
};

export default IconButton;
