import { Component } from 'react';
import { APIErrorContext } from '../../common/Errors/api-error-provider';
import { HumanizedError } from '../../common/Errors/errors';
import PropTypes from 'prop-types';
import { logError } from './log-error';

class ErrorBoundary extends Component {
  constructor (props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidCatch (error, info) {
    let { errorMessage } = this.props;

    console.error('component caught');

    if (error instanceof HumanizedError) {
      if (errorMessage) {
        errorMessage += ': ' + error.humanMessage;
      } else {
        errorMessage = error.humanMessage;
      }
    }

    logError(error, { errorMessage, ...info });

    if (error && error.name === 'NotLoggedInError') {
      const url = new URL(window.location);
      url.hash = '/';
      window.location = url.href;
      window.location.reload();

      return;
    }

    this.context.addError(errorMessage);
    this.setState({ hasError: true });
  }

  render () {
    if (this.state.hasError) return null;

    return this.props.children;
  }
}

ErrorBoundary.contextType = APIErrorContext;

ErrorBoundary.propTypes = {
  errorMessage: PropTypes.string
};

export default ErrorBoundary;
