export function logError (error, { errorMessage, componentStack }) {
  let msg = `${error.name}: ${error.message}\n${error.stack}`;

  if (errorMessage) {
    msg = `${errorMessage}: ${msg}`;
  }

  if (componentStack) {
    msg += `\n  Component Stack: ${componentStack}`;
  }

  console.error(msg);
}
