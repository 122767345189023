import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const ButtonWrapper = ({
  type,
  to,
  handleClick = () => {},
  onBlur,
  className,
  children,
  formType,
  target,
  disabled
}) => {
  if (target) {
    return (
      <a
        href={to}
        target={target}
        className={className}
        disabled={disabled}
        onClick={handleClick}
        onBlur={onBlur}
      >
        { children }
      </a>
    );
  }

  switch (type) {
    case 'navlink':
      if (disabled) throw new Error('disabled not yet supported for navlink');
      //TODO disabled for NavLink

      return (
        <NavLink
          to={to}
          className={className}
          onBlur={onBlur}
        >
          <div className="button-inner">
            { children }
          </div>
        </NavLink>
      );
    case 'link':
      if (disabled) throw new Error('disabled not yet supported for link');
      //TODO respect disabled prop

      return (
        <Link
          to={to}
          className={className}
          onBlur={onBlur}
        >
          <div className="button-inner">
            { children }
          </div>
        </Link>
      );
    default:
      return (
        <button
          className={className}
          onClick={(ev) => handleClick(ev)}
          type={formType || 'button'}
          disabled={disabled}
          onBlur={onBlur}
        >
          { children }
        </button>
      );
  }
};

export default ButtonWrapper;
