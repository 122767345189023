import React, { useState, useCallback } from 'react';

export const NavigationPromptContext = React.createContext({
  prompt: false,
  setPrompt: () => {},
  onClick: () => {},
  promptToConfirm: () => {}
});

export default function NavigationPromptContextProvider ({ children }) {
  const [ _prompt, setPrompt ] = useState(false);

  const promptToConfirm = useCallback((callback) => {
    return (...args) => {
      const result = !_prompt || window.confirm(_prompt);

      if (result) return callback(...args);
      return () => {};
    };
  }, [ _prompt ]);

  const onClick = useCallback((ev) => {
    if (!_prompt || window.confirm(_prompt)) return true;

    ev?.preventDefault();
    ev?.stopPropagation();

    return false;
  }, [ _prompt ]);

  const value = { prompt: _prompt, setPrompt, promptToConfirm, onClick };

  return (
    <NavigationPromptContext.Provider value={value}>
      {children}
    </NavigationPromptContext.Provider>
  );
}
